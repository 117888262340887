<script lang="ts" setup>
  import Form from '../../components/ui/Containers/Form.vue'
  import type { FormElement } from '../../types/inputs/FormElements'

  const router = useRouter()
  const { content } = useContentStore()
  const { getTranslation } = useTranslationStore()
  const config = useRuntimeConfig()
  const ValidationUrl = config.public.VERIFY_SUBSCRIPTION_ENDPOINT
  const brandCode = config.public.BRAND_CODE
  const marketCode = config.public.MARKET_CODE
  const loginPath = config?.public.LOGIN_URL || '/login'

  const firstName = ref('')
  const address = ref(null)
  const zipCode = ref(null)
  const subscriptionNumber = ref(null)
  const lastName = ref('')
  const email = ref('')

  const basicInfTitle = getTranslation('signup.breadcrumbs.credentials_form')
  const passwordTitle = getTranslation('auth.choose_new_password')
  const title = content.teasers?.data[0]?.title
  const description = content.teasers?.data[0]?.description

  const currentStep = computed(() => {
    return router.currentRoute?.value?.query?.step || false
  })

  const handleStartSubmit = async (formData: Record<string, any>) => {
    if (!formData.signupAbonementNumber || !formData.signupZipCode) {
      throw createError('Invalid credentials')
    }
    try {
      const response = await $fetch(ValidationUrl, {
        method: 'POST',
        body: {
          brand_code: brandCode,
          locale: marketCode,
          subscription_number: formData.signupAbonementNumber,
          postal_code: formData.signupZipCode,
        },
      })

      const { data: userData } = response
      if (userData?.userExist === '1') {
        router.push({
          path: loginPath,
          query: { hasExistingUser: 'true' },
        })
      } else {
        zipCode.value = userData?.postalCode
        address.value = userData?.address
        subscriptionNumber.value = userData?.subscriptionNumber

        router.push({
          path: '/signup',
          query: {
            step: 'basic-info',
          },
        })
      }
    } catch (error) {
      throw createError(error as Error)
    }
  }
  const handleBasicInfoSubmit = (formData: Record<string, any>) => {
    firstName.value = formData.firstName
    lastName.value = formData.lastName
    email.value = formData.email

    router.push({
      path: '/signup',
      query: {
        step: 'password',
      },
    })
  }

  const handlePasswordSubmit = async (formData: Record<string, any>) => {
    try {
      const response = await $fetch('/api/auth/createuser', {
        method: 'POST',
        body: {
          email: email.value,
          password: formData.password,
          firstName: firstName.value,
          lastName: lastName.value,
          subscriptionNumber: subscriptionNumber.value,
          zipCode: zipCode.value,
          address: address.value,
        },
      })

      const userResponse = response

      try {
        const loginResponse = await $fetch<AuthResponse>('/api/auth/login', {
          method: 'POST',
          body: {
            username: userResponse.email,
            password: userResponse.password,
          },
          credentials: 'include',
        })
        const redirectUrl = localStorage.getItem('returnUrl')
        navigateTo(redirectUrl || '/')
      } catch (error) {
        throw createError(error as Error)
      }
    } catch (error) {
      throw createError(error as Error)
    }
  }

  const startFormElements: FormElement[] = [
    {
      id: 'hasAbonement',
      element: 'markdown',
      content: description,
    },
    {
      id: 'Step1',
      element: 'markdown',
      content: `<strong> ${getTranslation('signup.subscription_form.subscription_number_label')} </strong> ${getTranslation('signup.step-one-of-three')}`,
    },
    {
      id: 'signupAbonementNumber',
      element: 'input',
      type: 'text',
      placeholder: getTranslation(
        'signup.subscription_form.subscription_number_label'
      ),
      debounce: true,
      error: getTranslation(
        'signup.subscription_form.subscription_number_error'
      ),
    },
    {
      id: 'forgotAbonementNumber',
      element: 'markdown',
      content: `<a href="${getTranslation('signup.subscription_form.forgot_subscription_number_link')}">${getTranslation('signup.subscription_form.forgot_subscription_number')}</a>`,
    },
    {
      id: 'signupZipCode',
      element: 'input',
      type: 'text',
      error: getTranslation('signup.subscription_form.postal_code_error'),
      placeholder: getTranslation('signup.subscription_form.postal_code_label'),
      debounce: true,
    },

    {
      id: 'submitButton',
      element: 'button',
      label: `${getTranslation('fields.next')} ${getTranslation('signup.subscription_form.submit_label')}`,
    },
  ]
  const basicFormElements: FormElement[] = [
    {
      id: 'hasAbonement',
      element: 'markdown',
      content: getTranslation('signup.step-two-of-three'),
    },
    {
      id: 'firstName',
      element: 'input',
      type: 'text',
      placeholder: getTranslation('signup.credentials_form.first_name_label'),
      debounce: true,
      error: getTranslation('signup.credentials_form.first_name_error'),
    },
    {
      id: 'lastName',
      element: 'input',
      type: 'text',
      placeholder: getTranslation('signup.credentials_form.last_name_label'),
      debounce: true,
      error: getTranslation('signup.credentials_form.last_name_error'),
    },
    {
      id: 'email',
      element: 'input',
      type: 'email',
      debounce: true,
      placeholder: getTranslation('signup.credentials_form.email_label'),
      error: getTranslation('signup.credentials_form.email_error'),
    },
    {
      id: 'submitButton',
      element: 'button',
      label: 'Næste',
    },
  ]
  const passwordFormElements: FormElement[] = [
    {
      id: 'hasAbonement',
      element: 'markdown',
      content: getTranslation('signup.step-three-of-three'),
    },
    {
      id: 'password',
      element: 'input',
      type: 'password',
      debounce: true,
      error: getTranslation('login.form.password_error'),
      placeholder: getTranslation('login.form.password_label'),
    },

    {
      id: 'submitButton',
      element: 'button',
      label: getTranslation('signup.credentials.submit_label'),
    },
  ]
</script>

<template>
  <main ref="loginPage" class="bp-main-grid center-content">
    <div
      class="center-content bg-surface-secondary p-15 shadow-default my-20 rounded-md"
    >
      <Form
        v-if="currentStep === 'password'"
        :title="passwordTitle"
        class=""
        :form-elements="passwordFormElements"
        @submit="handlePasswordSubmit"
      >
      </Form>

      <Form
        v-else-if="currentStep === 'basic-info'"
        :title="basicInfTitle"
        class=""
        :form-elements="basicFormElements"
        @submit="handleBasicInfoSubmit"
      >
      </Form>

      <Form
        v-else="currentStep"
        :title="title"
        class=""
        :form-elements="startFormElements"
        @submit="handleStartSubmit"
      >
      </Form>
    </div>
  </main>
</template>
